import { createApp } from './app';
import Vue from 'external/Vue';
import API from 'external/API';
import { INITIAL_APP_DATA, MOUNT_POINT_ID, DEFAULT_ORIGIN } from './constants';

let keyword = '';
let categoryTree = {};
let origin = DEFAULT_ORIGIN;
let customCrumbs = [];
let pageType;

if (window[INITIAL_APP_DATA]) {
  const dataString = window[INITIAL_APP_DATA];
  try {
    ({ keyword, categoryTree, origin, customCrumbs, pageType } = JSON.parse(decodeURI(dataString)));
  } catch (error) {
    console.log('INIT DATA ERROR ::', error);
  }
}

const app = createApp({ Vue, API }, { keyword, categoryTree, origin, customCrumbs, pageType });

app.$mount(`#${MOUNT_POINT_ID}`);
