const INITIAL_APP_DATA = '__INITIAL_PRODUCT_EXPLORER_BREADCRUMB_MOBILE__';
const INITIAL_APP_ID = 'initial-product-explorer-breadcrumb-mobile';
const MOUNT_POINT_ID = 'product-explorer-breadcrumb-mobile';
const DEFAULT_ORIGIN = 'https://m.bukalapak.com';

module.exports = {
  INITIAL_APP_DATA,
  INITIAL_APP_ID,
  MOUNT_POINT_ID,
  DEFAULT_ORIGIN,
};
