import Breadcrumb from './Breadcrumb.vue';

export const createApp = ({ Vue, API }, { keyword = '', categoryTree = {}, origin, customCrumbs, pageType } = {}) => {
  Vue.prototype.$breadcrumbApi = API.wrap({
    getCategoryBySlug(slug) {
      return this.get(`/categories/permalink/${slug}`);
    },
  });

  return new Vue({
    name: 'BreadcrumbApp',
    render: h =>
      h(Breadcrumb, {
        props: {
          initialKeyword: keyword,
          initialCategoryTree: categoryTree,
          initialOrigin: origin,
          customCrumbs,
          pageType,
        },
      }),
  });
};
