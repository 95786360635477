<template>
  <section class="dp-breadcrumb-container">
    <ul class="dp-breadcrumb" itemscope="itemscope" itemtype="http://schema.org/BreadcrumbList">
      <li
        v-for="(crumb, i) in crumbs"
        :key="crumb.name"
        class="dp-breadcrumb__item"
        itemprop="itemListElement"
        itemtype="http://schema.org/ListItem"
        itemscope="itemscope"
      >
        <a :href="crumb.url" itemprop="item" class="link">
          <span itemprop="name">{{ crumb.name | toCapitalize }}</span>
          <meta :content="i + 1" itemprop="position" />
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import _has from 'lodash/has';
import to from 'await-to-js';
import {
  formatCategoryDisplayName,
  getKeyword,
  isCategoryPath,
  getPermalink,
} from '../../shared/utilities/breadcrumbUtility';
import urlUtility from '../../shared/utilities/urlUtility';
import parameterize from '../../shared/utilities/parameterize';
import { isBhlm } from '@/shared/utilities/bhlmUtility';

const DEFAULT_TITLE = 'Semua hasil pencarian';

export default {
  name: 'Breadcrumb',
  filters: {
    toCapitalize(str) {
      if (typeof str !== 'string') return '';
      return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
  props: {
    initialKeyword: {
      type: String,
      required: true,
    },
    initialCategoryTree: {
      type: Object,
      default() {
        return {};
      },
    },
    initialOrigin: {
      type: String,
      required: true,
    },
    customCrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      keyword: this.initialKeyword || DEFAULT_TITLE,
      categoryTree: this.initialCategoryTree,
    };
  },
  computed: {
    isCategoryTreeExist() {
      return _has(this.categoryTree, 'permalink');
    },
    crumbs() {
      let crumbs = [
        {
          name: 'Home',
          url: this.initialOrigin,
        },
      ];

      if (this.customCrumbs.length) {
        return this.customCrumbs;
      }

      if (this.isCategoryTreeExist) {
        crumbs = this.generateCategoryCrumbs(this.categoryTree, crumbs);
      }

      // return without default title
      if (this.keyword === DEFAULT_TITLE) return crumbs;

      if (this.keyword.includes('Kategori')) return crumbs;

      crumbs.push({
        name: this.keyword,
        url: `${this.initialOrigin}/products/s/${parameterize(this.keyword)}`,
      });
      return crumbs;
    },
  },
  mounted() {
    // subscribe to mutation from Whitebox
    global.STORE.watch(
      state => {
        return state.productExplorer.searchUrl;
      },
      async watched => {
        const anchorElement = urlUtility.createUrlObj(watched);

        let newKeyword = getKeyword({
          path: anchorElement.pathname,
          query: anchorElement.search,
        });
        let newCategory = {};

        if (isCategoryPath(anchorElement.pathname) && !isBhlm(global.location.href)) {
          // fetch detail category
          const permalink = getPermalink(anchorElement.pathname);
          const [err, { data: category }] = await to(this.$breadcrumbApi.getCategoryBySlug(permalink));
          if (err) return;

          if (!newKeyword) newKeyword = `Kategori ${category.name}`;
          newCategory = category;
        }
        this.keyword = newKeyword || DEFAULT_TITLE;
        this.categoryTree = newCategory;
      },
      {},
    );
  },
  methods: {
    generateCategoryCrumbs(categoryTree, prevCrumbs = []) {
      const crumbsCopy = [...prevCrumbs];
      const { permalink } = this.categoryTree;
      const permalinks = permalink.split('/');

      permalinks.forEach((p, index) => {
        const previousUrl = crumbsCopy[crumbsCopy.length - 1].url;
        const crumb = {
          name: formatCategoryDisplayName(p),
          url: `${previousUrl}/${p}`,
        };

        if (index === 0) crumb.url = `/c/${p}`;

        crumbsCopy.push(crumb);
      });

      return crumbsCopy;
    },
  },
};
</script>
